import React from 'react';

import Button from '../Button';
import LocaleText from '../Intl/LocaleText';
import Popin from '../Popin';
import RoundProgress from '../RoundProgress';

import { TActivityPopinProps } from './interfaces';
import Styles from './styles.module.scss';

const ActivityPopin: React.FC<TActivityPopinProps> = ({ active = false, initialCounter = 120, onClick }) => {
  const [counter, setCounter] = React.useState(initialCounter);

  React.useEffect(() => {
    const clock = setInterval(() => {
      if (active) {
        if (counter > 0) {
          setCounter(counter - 1);
        }
      } else {
        //console.log(initialCounter);
        setCounter(initialCounter);
        clearInterval(clock);
      }
    }, 1000);
    return () => {
      clearInterval(clock);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, active]);

  return (
    <Popin active={active} onClickUnderlay={onClick}>
      <RoundProgress className={Styles.activityPopin__clock} progress={counter ? ((counter - 1) / (initialCounter - 1)) * 100 : 0} reverse>
        {counter}
      </RoundProgress>
      <p className={Styles['activityPopin__title']}>
        <LocaleText>global.activityPopin.title</LocaleText>
      </p>
      <Button onClick={onClick}>
        <LocaleText>global.activityPopin.cta</LocaleText>
      </Button>
    </Popin>
  );
};

export default ActivityPopin;
