import React from 'react';

import Styles from './styles.module.scss';
import {TPurchaseValidationStep} from './interfaces';
import {ProcessContext} from '..';
import Title from "@/components/Title";
import LocaleText from "@/components/Intl/LocaleText";
import Inner from "@/components/Inner";
import PurchaseCard from "@/components/PurchaseCard";
import Spinner from "@/components/Spinner";
import Button from "@/components/Button";
import Icon from "@/components/Icon";

const PurchaseValidationStep: React.FC<TPurchaseValidationStep> = ({products, onSubmit}) => {
    const [validationSubmitted, setValidationSubmitted] = React.useState<boolean>(false);
    const [selectedProducts, setSelectedProducts] = React.useState<number[]>([]);

    const {
        cartValidation: {
            success: cartValidation
        } = { success: null },
        recommendations: {
            routines
        },
        goToHome
    } = React.useContext(ProcessContext);

    const onProductSelect = (id: number, name: string, isInitial: boolean, active: boolean) => {
        console.log([
            id, name, isInitial, active
        ])
        let tempArray = selectedProducts;
        if(active) {
            tempArray.push(id)
            setSelectedProducts(tempArray)
        } else {
            tempArray = tempArray.filter(item => item !== id)
        }
        setSelectedProducts(tempArray)
    }

    if(products) {
        let routinesFiltered = routines.filter((routine: any) => {
            return routine.proposed_products.find(({ id }: { id: number }) => products.includes(id)) !== undefined
                || (routine.sub_routines.filter((sub: any) => {
                    return sub.proposed_products.find(({ id }: { id: number }) => products.includes(id)) !== undefined
                })).length > 0;
        });
        // if (routinesFiltered.length == 0) {
        //     onSubmit();
        // }

        return <div className={Styles['purchaseValidationStep']}>
            <Inner type='md' className={Styles['purchaseValidationStep__heading']}>
                <Title className={Styles['purchaseValidationStep__title']}>
                    <LocaleText>page.purchaseValidationStep.title</LocaleText>
                </Title>
                <Title className={Styles['purchaseValidationStep__subtitle']}>
                    { cartValidation ?(
                        <LocaleText>page.purchaseValidationStep.subtitle</LocaleText>
                    ) : (
                        <LocaleText>page.purchaseValidationStep.empty.subtitle</LocaleText>
                    )}
                </Title>
            </Inner>
            <div className={Styles['purchaseValidationStep__body']}>
                {routinesFiltered
                    .sort((a: any, b: any) => a.position - b.position)
                    .map((routine: any, rIndex: number) => {
                        return <div>
                            <div key={'purchaseCard-' + (routine.id ?? rIndex)} className={Styles['purchaseValidationStep__routine__container']}>
                                <div className={Styles['purchaseValidationStep__routine__title']}>
                                    <span>{rIndex + 1}.</span>
                                    {routine.title}
                                </div>
                                {routine.proposed_products.find(({ id }: { id: number }) => products.includes(id)) !== undefined ?
                                    <PurchaseCard
                                        className={Styles['purchaseValidationStep__routine__card']}
                                        {...routine.proposed_products.find(({ id }: { id: number }) => products.includes(id))}
                                        onSelect={(id, name, isInitial, active) => onProductSelect(id, name, isInitial, active)}
                                    /> : ''
                                }
                            </div>
                            {routine.sub_routines.map((sub: any, sIndex: number) => (
                                <div key={'purchaseCard-' + (routine.id ?? rIndex) + '-' + (sub.id ?? sIndex)}
                                     className={Styles['purchaseValidationStep__routine__container']}>
                                    {sub.proposed_products.find(({ id }: { id: number }) => products.includes(id)) !== undefined ?
                                        <PurchaseCard
                                            className={Styles['purchaseValidationStep__routine__card']}
                                            {...sub.proposed_products.find(({ id }: { id: number }) => products.includes(id))}
                                            onSelect={(id, name, isInitial, active) => onProductSelect(id, name, isInitial, active)}
                                        /> : ''
                                    }
                                </div>
                            ))}
                        </div>
                    })}
            </div>
            <div className={Styles['purchaseValidationStep__footer']}>
                <Inner type='md' className={Styles['purchaseValidationStep__footer-inner']}>
                    <p className={Styles['purchaseValidationStep__footer-selected']}>
                        {products.length} <LocaleText plural={products.length !== 1}>page.purchaseValidationStep.selectedProduct</LocaleText>
                    </p>
                    { cartValidation ? (
                        <Button
                            disabled={validationSubmitted}
                            className={Styles['purchaseValidationStep__button']}
                            onClick={() => {
                                setValidationSubmitted(true);
                                onSubmit(selectedProducts);
                                setTimeout(() => goToHome(), 3000)
                                // console.log(selectedProducts)
                            }}
                        >
                            {validationSubmitted ? (
                                <>
                                    <Icon icon="check"/><LocaleText>page.purchaseValidationStep.sent</LocaleText>
                                </>
                            ) : (
                                <LocaleText>page.purchaseValidationStep.submit</LocaleText>
                            )}
                        </Button>
                    ) : (
                        <Button
                            className={Styles['purchaseValidationStep__button']}
                            onClick={() => goToHome()}
                        >
                            <LocaleText>page.purchaseValidationStep.empty.submit</LocaleText>
                        </Button>
                    )}

                </Inner>
            </div>
        </div>;
    }

    return <div className={Styles['purchaseValidationStep']}>
        <Spinner className={Styles['purchaseValidationStep__loading']} />
    </div>;
};

export default PurchaseValidationStep;