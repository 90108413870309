import React from 'react';

import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import RecommendationCard from '@/components/RecommandationCard';
import Styles from './styles.module.scss';

import { TRoutine } from './interfaces';
import { TCustomerProposedProduct } from '@/helpers/API/requests/interfaces/customer';
import SubRoutine from '@/components/SubRoutine';
import Button from '../Button';
import Icon from '@/components/Icon';

const Routine: React.FC<TRoutine> = ({ index, rIndex, routine, isCurrentStep, active, recommendationRoutines, onStepChange, onCardSelect, onRoutineReset }) => {
  const [activeCard, setActiveCard] = React.useState(routine.proposed_products.findIndex(({ id }: any) => id === active));
  const [currentProduct, setCurrentProduct] = React.useState<Partial<TCustomerProposedProduct> | null>(null);
  const [showSubRoutines, setShowSubRoutines] = React.useState(false);

  const onProductSelect = (id: number, name: string, isInitial: boolean, active: boolean, routineIndex: number, pIndex: number, image_url?: string) => {
    if (active) {
      setActiveCard(pIndex);
      setCurrentProduct({
        name,
        image_url,
      });
    } else {
      setActiveCard(-1);
      setCurrentProduct(null);
    }

    onCardSelect(id, active, routineIndex);
  };

  const routineReset = (routineIndex: number) => {
    setActiveCard(-1);
    setCurrentProduct(null);
    onStepChange(routineIndex);
    onRoutineReset(routineIndex);
  };

  React.useEffect(() => {
    const indexFound = routine.proposed_products.findIndex(({ id }: any) => id === active);

    setActiveCard(indexFound);
    if (indexFound !== -1) {
      setCurrentProduct({
        name: routine.proposed_products[indexFound].name,
        image_url: routine.proposed_products[indexFound].image_url,
      });
    }
  }, [active, routine.proposed_products]);

  return (
    <div key={'recommendationRoutine-' + rIndex} className={Styles['recommendationRoutine']}>
      <Inner
        className={Styles['recommendationRoutine__heading']}
        type={'full'}
        onClick={() => {
          onStepChange(rIndex);
        }}
      >
        <div className={Styles['recommendationRoutine__title']}>
          <span>{rIndex + 1}.</span>
          {routine.title}
        </div>
        <div className={Styles['recommendationRoutine__status']}>
          {currentProduct ? (
            <Button className={Styles['recommendationRoutine__status-product']} size='small' outline={true}>
              <span>{currentProduct.name}</span>
              <div
                className={Styles['recommendationRoutine__status-product-delete']}
                onClick={(e) => {
                  e.stopPropagation();
                  routineReset(index);
                }}
              >
                <Icon icon='cross' />
              </div>
            </Button>
          ) : (
            <Button className={Styles['recommendationRoutine__status-none']} size='small' outline={true}>
              <LocaleText>page.recommendationStep.noProduct</LocaleText>
            </Button>
          )}
        </div>
      </Inner>
      <div className={Styles['recommendationRoutine__body']}>
        <Inner type='full'>
          <div className={`${Styles['recommendationRoutine__slider']} ${routine.proposed_products.length === 1 && Styles['center']}`}>
            {routine?.proposed_products?.map((product: any, pIndex: number) => (
              <RecommendationCard
                key={'recommendationCard-' + pIndex}
                {...product}
                isActive={activeCard === pIndex}
                onSelect={(id, name, isInitial, active, i, image_url) => onProductSelect(id, name, isInitial, active, index, pIndex, image_url)}
              />
            ))}
          </div>
        </Inner>
      </div>
      <div className={Styles['recommendationRoutine__footer']}>
        <Inner type='full'>
          {routine.sub_routines.length > 0 ? (
            <p
              className={Styles['recommendationRoutine__sub-more']}
              onClick={() => {
                setShowSubRoutines(!showSubRoutines);
              }}
            >
              <LocaleText>page.recommendationStep.subRoutines</LocaleText>
            </p>
          ) : (
            ''
          )}
        </Inner>
        <div className={`${Styles['recommendationRoutine__subs']} ${showSubRoutines ? Styles['recommendationRoutine__subs--active'] : ''}`}>
          {routine.sub_routines
            .sort((a: any, b: any) => a.position - b.position)
            .map((sub_routine: any, rIndex: number) => (
              <SubRoutine
                key={'recommendationRoutine-' + index + '-sub-' + rIndex}
                index={index + rIndex + 1}
                rIndex={rIndex}
                parentIndex={index}
                routine={sub_routine}
                active={recommendationRoutines?.[index + rIndex + 1] as number | undefined}
                isCurrentStep={isCurrentStep}
                onStepChange={onStepChange}
                onCardSelect={onCardSelect}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Routine;
