export enum ECustomerGender {
  /**
   * The customer is a man
   */
  MAN = 'man',

  /**
   * The customer is a woman
   */
  WOMAN = 'woman',
}

export enum ECustomerAge {
  /**
   * The customer is an adolescent (between 12 and 24 year old)
   */
  ADO = '16-24',

  /**
   * The customer is an young adult (between 25 and 34 year old)
   */
  YOUNG_ADULT = '25-34',

  /**
   * The customer is a senior adult (between 35 and 54 year old)
   */
  SENIOR_ADULT = '35-54',

  /**
   * The customer is an elder (between 55 and 79 year old)
   */
  ELDER = '55-79',
}

export enum ECustomerSkin {
  /**
   * The customer's skin is normal
   */
  NORMAL = 'normale',

  /**
   * The customer's skin is dried
   */
  DRIED = 'seche',

  /**
   * The customer's skin is fat
   */
  FAT = 'grasse',

  /**
   * The customer's skin is mixed
   */
  MIXED = 'mixte',
}

export enum ECustomerPathology {
  /**
   * The customer has no pathology.
   */
  NULL = 'none',

  /**
   * The customer has eczema.
   */
  ECZEMA = 'eczema',

  /**
   * The customer has acne.
   */
  ACNE = 'acne',

  /**
   * The customer has psoriasis
   */
  PSORIASIS = 'psoriasis',

  /**
   * The customer has dermatite sebborheique
   */
  DERMATITE_SEBBORHEIQUE = 'dermatite_sebborheique',

  /**
   * The customer has couperose
   */
  ROSACE_COUPEROSE = 'rosace_couperose',

  // /**
  //  * The customer has rosace
  //  */
  // ROSACE = 'rosace',
}

export enum ECustomerSkinProblem {
  /**
   * The customer's main problem to solve is hydration
   */
  HYDRATION = 'hydratation',

  /**
   * The customer's main problem to solve is wrinkles
   */
  WRINKLES = 'rides',

  // /**
  //  * The customer's main problem to solve is acne
  //  */
  // ACNE = 'acne',

  /**
   * The customer's main problem to solve is firmness
   */
  FIRMNESS = 'fermete',

  /**
   * The customer's main problem to solve is pores
   */
  PORES = 'pores',

  /**
   * The customer's main problem to solve is brightness
   */
  BRIGHTNESS = 'teint_eclat',

  /**
   * The customer's main problem to solve is stain
   */
  STAIN = 'tache',

  // /**
  //  * The customer's main problem to solve is eye's outline
  //  */
  // EYE_OUTLINE = 'contour_de_oeil',
}

export enum ECustomerSkinProblemSlug {
  /**
   * The customer's main problem to solve is hydration
   */
  HYDRATION = 'Hydration',

  /**
   * The customer's main problem to solve is wrinkles
   */
  WRINKLES = 'wrinkles',

  /**
   * The customer's main problem to solve is acne
   */
  ACNE = 'acne',

  /**
   * The customer's main problem to solve is firmness
   */
  FIRMNESS = 'sagging',

  /**
   * The customer's main problem to solve is pores
   */
  PORES = 'pores',

  /**
   * The customer's main problem to solve is brightness
   */
  BRIGHTNESS = 'translucency',

  /**
   * The customer's main problem to solve is stain
   */
  STAIN = 'pigmentation',

  /**
   * The customer's main problem to solve is eye's outline
   */
  EYE_OUTLINE = 'eye_bags',
}

export type TCustomerSelfieBody = {
  /**
   * Selfie picture sent as base 64.
   */
  base64: string;
};

export type TCustomerSelfieResponse = {
  /**
   * Selfie entity's id.
   */
  batchId: string;
  /**
   * Selfie's image id.
   */
  imageId: string;
};

export type TCustomerAnswerBody = TCustomerSelfieResponse & {
  /**
   * Customer's email.
   */
  customer_email: string;

  /**
   * Customer's gender.
   */
  gender: ECustomerGender;

  /**
   * Customer's age range.
   */
  age: ECustomerAge;

  /**
   * Customer's pathology.
   */
  pathology: ECustomerPathology;

  /**
   * Customer's skin type.
   */
  skin: ECustomerSkin;

  /**
   * Is customer's skin sensitive ?
   */
  sensitive_skin: boolean;

  /**
   * Customer's problem
   */
  problem: ECustomerSkinProblem;

  /**
   * Customer want to test eye's outline.
   */
  eye_area: boolean;

  /**
   * Check if user is on mobile device
   */
  is_mobile: boolean;

  campaign: string;
};

export type TCustomerProposedProductBrand = {
  /**
   * Brand's id.
   */
  id: number;

  /**
   * Brand's name.
   */
  name: string;
};

export type TCustomerProposedProduct = {
  /**
   * Customer proposed product's id.
   */
  id: number;

  /**
   * Customer proposed product's name.
   */
  name: string;

  /**
   * Customer proposed product's description.
   */
  description: string;

  /**
   * Customer proposed product's brand.
   */
  brand: TCustomerProposedProductBrand;

  /**
   * Is customer proposed product bought.
   */
  is_bought: boolean;

  /**
   * Customer proposed product image's url.
   */
  image_url: string;
};

export type TCustomerProposedProducts = TCustomerProposedProduct[];

export type TCustomerRoutine = {
  /**
   * Customer routine's id.
   */
  id: number;

  /**
   * Customer routine's title.
   */
  title: string;

  /**
   * Customer routine's position.
   */
  position: number;

  /**
   * Customer routine's proposed_products.
   */
  proposed_products: TCustomerProposedProducts;
};

export type TCustomerRoutines = TCustomerRoutine[];

export enum ECustomerMaskFeatureGeometryType {
  /**
   * Customer mask feature geometry type is a multi polygon.
   */
  MultiPolygon = 'MultiPolygon',

  /**
   * Customer mask feature geometry type is a multi point.
   */
  MultiPoint = 'MultiPoint',

  /**
   * Customer mask feature geometry type is a multi lines.
   */
  MultiLineString = 'MultiLineString',
}

/**
 * Multi polygon's points are characterize by x and y
 */
export type TMultiPolygonCoordinatePoint = [number, number];

export type TCustomerMaskFeatureGeometryMultiPolygonCoordinate = TMultiPolygonCoordinatePoint[][];

export type TCustomerMaskFeatureGeometryMultiPolygonCoordinates = TCustomerMaskFeatureGeometryMultiPolygonCoordinate[];

/**
 * Multi points' points are characterize by x, y and radius
 */
export type TMultiPointCoordinatePoint = [number, number, number];

export type TCustomerMaskFeatureGeometryMultiPointsCoordinate = TMultiPointCoordinatePoint;

export type TCustomerMaskFeatureGeometryMultiPointsCoordinates = TCustomerMaskFeatureGeometryMultiPointsCoordinate[];

/**
 * Multi lines' points are characterize by x, y
 */
export type TMultiLinesCoordinatePoint = [number, number];

export type TCustomerMaskFeatureGeometryMultiLinesCoordinate = [TMultiLinesCoordinatePoint, TMultiLinesCoordinatePoint];

export type TCustomerMaskFeatureGeometryMultiLinesCoordinates = TCustomerMaskFeatureGeometryMultiLinesCoordinate[];

export type TCustomerMaskFeatureGeometry = {
  /**
   * Customer mask feature geometry's type.
   */
  type: ECustomerMaskFeatureGeometryType;

  /**
   * Customer mask feature geometry's coordinates.
   */
  coordinates:
    | TCustomerMaskFeatureGeometryMultiPolygonCoordinates
    | TCustomerMaskFeatureGeometryMultiPointsCoordinates
    | TCustomerMaskFeatureGeometryMultiLinesCoordinates;
};

export type TCustomerMaskFeatureProperties = {
  /**
   * Customer mask feature properties's intensity.
   */
  intensity: number;
};

export type TCustomerMaskFeature = {
  /**
   * Customer mask feature's geometry.
   */
  geometry: TCustomerMaskFeatureGeometry;

  /**
   * Customer mask feature's geometry.
   */
  properties: TCustomerMaskFeatureProperties;
};

export type TCustomerMaskFeatures = TCustomerMaskFeature[];

export enum ECustomerMaskType {
  /**
   * Customer mask type is a polygon.
   */
  polygon_mask = 'polygon_mask',

  /**
   * Customer mask type is a point.
   */
  point_mask = 'point_mask',

  /**
   * Customer mask type is a polyline.
   */
  polyline_mask = 'polyline_mask',

  /**
   * Customer mask type is a heatmap.
   */
  heatmap_mask = 'heatmap_mask',
}

export type TCustomerMask = {
  /**
   * Customer mask's type
   */
  mask_type: ECustomerMaskType;

  /**
   * Customer mask's features
   */
  features: TCustomerMaskFeatures;

  /**
   * Customer mask's view box dimension.
   */
  view_box: string;

  /**
   * Customer mask's fill color.
   */
  fill: string;

  /**
   * Customer mask's name
   */
  name: string;

  /**
   * Customer mask technology's name
   */
  tech_name: string;

  /**
   * Customer mask's type
   */
  type: string;
};

export type TCustomerMasks = TCustomerMask[];

export type TCustomerCategory = {
  /**
   * Customer category's name.
   */
  name: string;

  /**
   * Customer category's score.
   */
  score: number;

  /**
   * Customer category's description.
   */
  description: string;

  /**
   * Customer category's description.
   */
  masks: TCustomerMasks;
};

export type TCustomerCategories = TCustomerCategory[];

export type TCustomerAnswerResponse = {
  /**
   * Customer cart's id.
   */
  cart_id: number;

  /**
   * Customer routines.
   */
  routines: TCustomerRoutines;

  /**
   * Customer areas' categories.
   */
  categories: TCustomerCategories;
};

export type TCustomerCartValidationBody = {
  /**
   * Checked product's ids
   */
  checked_products: Array<number>;
};

export type TCustomerCartValidationResponse = {
  /**
   * Checked product's ids
   */
  success: boolean;
};

export type TCustomerSendRecapItems = {
  /**
   * Item's title
   */
  title: ECustomerSkinProblemSlug;

  /**
   * Item's score
   */
  score: string | number;
};

export type TCustomerSendRecapBody = {
  /**
   * Customer's Email
   */
  email: string;

  /**
   * Customer's array of items and scores
   */
  items: Array<TCustomerSendRecapItems>;
};

export type TCustomerSendRecapResponse = {
  /**
   * Response, success or failure
   */
  success: boolean;
};

export type TPurchasedProductsBody = {
  /**
   * selected products ids
   */
  ids: number[];
};

export type TPurchasedProductsResponse = {
  /**
   * Response, success or failure
   */
  success: boolean;
};

export type TGenerateCampaignResponse = {
  /**
   * The JWT token.
   */
  token: string;

  /**
   * Information related to the laboratory.
   */
  laboratory: {
    /**
     * The first color associated with the laboratory.
     */
    color1: string;

    /**
     * The second color associated with the laboratory.
     */
    color2: string;

    /**
     * The title related to the laboratory.
     */
    title: string;

    /**
     * The subtitle related to the laboratory.
     */
    subtitle: string;

    /**
     * The description of the laboratory.
     */
    description: string;

    /**
     * The logo of the laboratory, encoded in base64.
     */
    logo: string;

    /**
     * The homepage image of the laboratory, encoded in base64.
     */
    image: string;

    /**
     * Skip the SensibilityForm if true.
     */
    hideSensitiveSkin: boolean;

    /**
     * List the pathologies checkbox to show in the PathologyForm.
     */
    availablePathologies: Array<string>;

    /**
     * PDF document (in Base64) of the terms and conditions of the laboratory
     */
    document: string;

    /**
     * The name of the laboratory
     */
    name: string;
  };

  campaign: {
    /**
     * Campaign dateStart
     */
    dateStart: Date;
    /**
     * Campaign dateEnd
     */
    dateEnd: Date;
    /**
     * Campaign secondsLeft
     */
    secondsLeft: number;
  };
};

export type TLabInfosResponse = {
  /**
   * The JWT token.
   */
  token: string;

  /**
   * Information related to the laboratory.
   */
  laboratory: {
    /**
     * The first color associated with the laboratory.
     */
    color1: string;

    /**
     * The second color associated with the laboratory.
     */
    color2: string;

    /**
     * The title related to the laboratory.
     */
    title: string;

    /**
     * The subtitle related to the laboratory.
     */
    subtitle: string;

    /**
     * The description of the laboratory.
     */
    description: string;

    /**
     * The logo of the laboratory, encoded in base64.
     */
    logo: string;

    /**
     * The homepage image of the laboratory, encoded in base64.
     */
    image: string;
  };
};

export type TCodeValidationResponse = {
  /**
   * Response, success or failure
   */
  success: boolean;
};

export type TProcessType = 'campaign' | 'pharmacy';

export type TCustomerSelfie = (processType: TProcessType, body: TCustomerSelfieBody, locale: String) => Promise<TCustomerSelfieResponse>;

export type TCustomerAnswer = (processType: TProcessType, body: TCustomerAnswerBody, locale: string, campaign?: string) => Promise<TCustomerAnswerResponse>;

export type TCustomerCartValidation = (processType: TProcessType, id: number, body: TCustomerCartValidationBody) => Promise<TCustomerCartValidationResponse>;

export type TCustomerSendRecap = (processType: TProcessType, body: TCustomerSendRecapBody, locale: string) => Promise<TCustomerSendRecapResponse>;

export type TPurchasedProducts = (id: number, body: TPurchasedProductsBody) => Promise<TPurchasedProductsResponse>;

export type TCodeValidation = (cartId: number, operatorCode: number) => Promise<TCodeValidationResponse>;
