import React from 'react';

import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import RecommendationCard from '@/components/RecommandationCard';
import Styles from './styles.module.scss';

import { TSubRoutine } from './interfaces';
import {TCustomerProposedProduct} from "@/helpers/API/requests/interfaces/customer";
import Button from "@/components/Button";
import Icon from "@/components/Icon";

const Routine: React.FC<TSubRoutine> = ({
    index,
    rIndex,
    parentIndex,
    routine,
    isCurrentStep,
    active,
    onCardSelect,
    // onRoutineReset,
 }) => {
    const [activeCard, setActiveCard] = React.useState(routine.proposed_products.findIndex(({ id }: any) => id === active));
    const [currentProduct, setCurrentProduct] = React.useState<Partial<TCustomerProposedProduct> | null>(null);

    const onProductSelect = (id: number, name: string, isInitial: boolean, active: boolean, routineIndex: number, pIndex: number, image_url?: string) => {
        if (active) {
            setActiveCard(pIndex);
            setCurrentProduct({
              name,
              image_url
            });
        } else {
            setActiveCard(-1);
            setCurrentProduct(null);
        }
        
        onCardSelect(id, active, routineIndex);
    };

    const routineReset = (routineIndex: number) => {
        setActiveCard(-1);
        setCurrentProduct(null);
        // onStepChange(routineIndex);
        // onRoutineReset(routineIndex);
    };

    React.useEffect(() => {
        const indexFound = routine.proposed_products.findIndex(({ id }: any) => id === active);

        setActiveCard(indexFound);
        if (indexFound !== -1) {
            setCurrentProduct({
              name: routine.proposed_products[indexFound].name,
              image_url: routine.proposed_products[indexFound].image_url
            });
        }
    }, [active, routine.proposed_products]);

    return (
        <div key={'recommendationRoutine-' + parentIndex + '-sub-' + rIndex} className={`${Styles['recommendationRoutine']} ${isCurrentStep ? Styles['recommendationRoutine--active'] : ''}`}>
            <Inner className={Styles['recommendationRoutine__heading']}>
                <div className={Styles['recommendationRoutine__title']}>
                    <span>{parentIndex + 1}.{rIndex+1}</span>
                    {routine.title}
                </div>
                <div className={Styles['recommendationRoutine__status']}>
                    {currentProduct ? (

                        // <img src={currentProduct!.image_url} className={Styles['recommendationCard__visual-image']} alt='card_visual' />
                        <Button className={Styles['recommendationRoutine__status-product']} size="small" outline={true}>
                            <span>{currentProduct.name}</span>
                            <div className={Styles['recommendationRoutine__status-product-delete']} onClick={(e) => {
                                e.stopPropagation()
                                routineReset(index)
                            }}><Icon icon="cross"/></div>
                        </Button>
                    ) : (
                        <Button className={Styles['recommendationRoutine__status-none']} size="small" outline={true}>
                            <LocaleText>page.recommendationStep.noProduct</LocaleText>
                        </Button>
                    )}
                </div>
            </Inner>
            <div className={Styles['recommendationRoutine__body']}>
                <Inner type="full">
                    <div className={Styles['recommendationRoutine__slider']}>
                    {routine?.proposed_products?.map((product: any, pIndex: number) => (
                        <RecommendationCard 
                            key={'recommendationCard-' + pIndex}
                            {...product}
                            isVisible={isCurrentStep}
                            isActive={activeCard === pIndex}
                            onSelect={(id, name, isInitial, active, i, image_url, ) => onProductSelect(id, name, isInitial, active, index, pIndex, image_url)}
                        />
                    ))}
                    </div>
                </Inner>
            </div>
        </div>
    )
};

export default Routine;