const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

// TODO Implement type based on LIQA doc
export const provideBase64Image = async (event: any) => {
  const capture = event.detail;
  const imageBlob = await capture.blob();
  const base64 = await blobToBase64(imageBlob);
  return base64;
};
