import React from 'react';
import { isMobile } from 'react-device-detect';

import Breadcrumb from '@/components/Breadcrumb';
import Button from '@/components/Button';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import Routine from '@/components/Routine';
import Spinner from '@/components/Spinner';
import Title from '@/components/Title';

import { ProcessContext } from '..';
import { LocaleContext } from '@/providers/LocaleProvider';

import Styles from './styles.module.scss';
import { TRecommendationStep } from './interfaces';
import PopinError from '@/components/PopinError';
import AbandonPopin from '@/components/PopinAbandon';

const RecommendationStep: React.FC<TRecommendationStep> = ({ products, onSubmit }) => {
  const { recommendations, resetClock, goToHome } = React.useContext(ProcessContext);
  const localeContext = React.useContext(LocaleContext);

  const [currentStep, setCurrentStep] = React.useState(0);
  const [routinesLimit, setRoutinesLimit] = React.useState(4);
  const [routines, setRoutines] = React.useState<Array<string | number | null>>([]);
  const [productList, setProductList] = React.useState<Array<number>>(products ?? []);
  const [errorRecoNotFoundPopin, setErrorRecoNotFoundPopin] = React.useState(false);
  const [abandonPopin, setAbandonPopin] = React.useState<boolean>(false);

  const onCardSelect = React.useCallback(
    (id: number, active: boolean, routineIndex: number) => {
      const tempList = routines.slice();
      resetClock();
      if (active) {
        tempList.splice(routineIndex, 1, id);
        setRoutines(tempList);
        // setCurrentStep(routineIndex + 1);
      } else {
        tempList.splice(routineIndex, 1, null);
        setRoutines(tempList);
      }
    },
    [resetClock, routines]
  );

  const onRoutineReset = React.useCallback(
    (routineIndex: number) => {
      const tempList = routines.slice();

      tempList.splice(routineIndex, 1, null);
      setRoutines(tempList);
    },
    [routines]
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    // TODO To be confirmed: never true because recommendations is an empty array when there is none
    setErrorRecoNotFoundPopin(undefined === recommendations);
    let routines: Array<string | number | null> = [];
    if (recommendations?.routines) {
      recommendations.routines.forEach((routine: any) => {
        const p = routine.proposed_products.find(({ id }: any) => products?.includes(id));
        routines.push(p ? p.id : false);
        routine.sub_routines.forEach((sub: any) => {
          const p = sub.proposed_products.find(({ id }: any) => products?.includes(id));
          routines.push(p ? p.id : false);
        });
      });
    }
    setRoutines(routines);
  }, [products, recommendations, goToHome, localeContext.locale]);

  React.useEffect(() => {
    setProductList(
      routines
        .slice()
        .filter((p) => !!p)
        .map((p) => Number(p))
    );
  }, [routines]);

  const recommendationsErrorPopin = (
    <PopinError
      active={errorRecoNotFoundPopin}
      onClose={goToHome}
      title={'global.popinErrorRecoNotFound.title'}
      description={'global.popinErrorRecoNotFound.description'}
      backHome={'global.popinErrorRecoNotFound.backHome'}
    />
  );
  let indexRoutine = 0;

  return recommendations?.routines ? (
    <div className={Styles['recommendationStep']}>
      <Breadcrumb step={isMobile ? 3 : 4} />
      <Inner type='md' className={Styles['recommendationStep__heading']}>
        <Title className={Styles['recommendationStep__title']}>
          <LocaleText>page.recommendationStep.title</LocaleText>
        </Title>
        <Title className={Styles['recommendationStep__subtitle']}>
          <LocaleText>page.recommendationStep.subtitle</LocaleText>
        </Title>
      </Inner>

      <div className={Styles['recommendationStep__body']}>
        {recommendations.routines
          .sort((a: any, b: any) => a.position - b.position)
          .slice(0, routinesLimit)
          .map((routine: any, rIndex: number) => {
            let el = (
              <Routine
                key={'recommendationRoutine-' + rIndex}
                index={indexRoutine}
                rIndex={rIndex}
                routine={routine}
                active={routines?.[indexRoutine] as number | undefined}
                recommendationRoutines={routines}
                isCurrentStep={currentStep === rIndex ? true : false}
                onStepChange={setCurrentStep}
                onCardSelect={onCardSelect}
                onRoutineReset={onRoutineReset}
              />
            );
            indexRoutine = indexRoutine + 1 + routine.sub_routines.length;
            return el;
          })}
        {recommendations.routines.length > routinesLimit ? (
          <p className={Styles['recommendationStep__more']}>
            <Button size='medium' outline={true} onClick={() => setRoutinesLimit(1000)} className={Styles['recommendationStep__more--button']}>
              <LocaleText>page.recommendationStep.more</LocaleText>
            </Button>
          </p>
        ) : (
          ''
        )}
      </div>
      <div className={Styles['recommendationStep__footer']}>
        <Inner type='md' className={Styles['recommendationStep__submit']}>
          <Button fullwidth={true} outline={true} onClick={() => onSubmit(productList)} className={Styles['recommendationStep__button']}>
            <LocaleText>page.recommendationStep.submit</LocaleText>
          </Button>
        </Inner>
        <div className={Styles['recommendationStep__abandon']}>
          <p
            onClick={() => {
              setAbandonPopin(true);
            }}
          >
            <LocaleText>page.recommendationStep.abandon</LocaleText>
          </p>
        </div>
      </div>
      <AbandonPopin
        active={abandonPopin}
        onContinue={() => {
          setAbandonPopin(false);
        }}
      />
      {recommendationsErrorPopin}
    </div>
  ) : (
    <div className={Styles['recommendationStep']}>
      <Breadcrumb step={isMobile ? 3 : 4} />
      <Spinner className={Styles['recommendationStep__loading']} />
      {recommendationsErrorPopin}
    </div>
  );
};

export default RecommendationStep;
